import { Link as RouterLink } from "react-router-dom";
import Ternary from "src/components/ternary";
import Translate from "src/components/translate";
import { PATH_AUTH } from "src/routes/paths";
import { Button, Stack, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { WORD_PRESS_REFERRAL_LINK } from "src/config";

const GetStartedTitle = ({ login = false }) => {
  const smUp = useResponsive("up", "sm");

  return (
    smUp && (
      <Stack direction="row" alignItems="center" spacing={2}>
        <LanguagePopover />
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="body2">
            <Ternary
              when={login}
              then={<Translate>register.already</Translate>}
              otherwise={<Translate>register.don't_have</Translate>}
            />
            &nbsp;&nbsp;
          </Typography>
          <a
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#07325a",
            }}
            href={WORD_PRESS_REFERRAL_LINK}
          >
            <Translate>register.get_started</Translate>
          </a>
        </Stack>
      </Stack>
    )
  );
};

export default GetStartedTitle;
