import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import useGetLogo from "./hooks/use-logo";
import defaultLogo from "src/images/image001.png";
const Logo = ({ disabledLink = false }) => {
  const logo = useGetLogo();

  if (disabledLink) {
    return (
      <img
        style={{ width: "200px", height: "auto", maxHeight: "64px" }}
        src={logo ? logo : defaultLogo}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img
        style={{ width: "200px", height: "auto" }}
        src={logo ? logo : defaultLogo}
      />
    </RouterLink>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
