import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import { PATH_AUTH } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";
import notFound from "src/images/person.png";
import Logo from "src/components/logo";
import { styled } from "@mui/material/styles";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const OAuth = () => {
  const { getUser } = useAuth();
  const { g_token } = useParams();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const { queryObject } = useQueryParams();

  const handleSuccess = async (email, plan) => {
    const reqData = new FormData();
    reqData.append("email", email);
    reqData.append("plan", plan);
    try {
      const { status, data } = await axiosInstance.post(
        "api/google-login",
        reqData
      );

      if (status === 200) {
        const { access_token, user, menu_lists, package_status } =
          data?.data || {};
        if (Boolean(access_token)) {
          localStorage.setItem("menu", JSON.stringify(menu_lists));
          setSession(access_token);
          localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
          localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
          localStorage.setItem("package_status", Boolean(package_status));
          getUser();

          window.location = "/";
        } else {
          navigate(PATH_AUTH.login, { state: data.message, replace: true });
        }
      }
    } catch (err) {
      setMessage(err.message);
      console.error(err);
    }
  };

  const getLoggedInUserInfo = async (g_token) => {
    try {
      const { status, data } = await axios(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${g_token}`,
        {
          headers: {
            Authorization: `Bearer ${g_token}`,
            Accept: "application/json",
          },
        }
      );
      if (status === 200) {
        handleSuccess(data.email, queryObject?.plan);
      }
    } catch (err) {
      console.error(console.error("Retrieving Failed:", err));
    }
  };

  useEffect(() => {
    if (g_token) {
      getLoggedInUserInfo(g_token);
    }
  }, [g_token]);

  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Ternary
        when={message}
        then={
          <>
            {" "}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 4, // Adds padding for better spacing
                textAlign: "center", // Centers text alignment
                gap: 3, // Adds space between components
              }}
            >
              <Stack spacing={2} sx={{ maxWidth: 500 }}>
                <Typography variant="h3" color="primary" gutterBottom>
                  {message}!
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  No account found. Please ensure your email address is correct
                  and try logging in again.
                </Typography>
              </Stack>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={notFound} alt="Not Found" width={300} />
              </Box>
            </Box>
          </>
        }
        otherwise={
          <>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack spacing={3}>
                <CircularProgress size={130} disableShrink />
                <Typography>Loading Please Wait...</Typography>
              </Stack>
            </Box>
          </>
        }
      />
    </>
  );
};

export default OAuth;
